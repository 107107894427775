<script>
	import { supabaseClient } from '$lib/db';
	import { session } from '$app/stores';
	import { truncateText } from '$lib/js/text_utils';
	import { appStores } from '$lib/stores/appStores';
	import { Translator } from '$lib/js/translations/Translator';
	import { checkEnvironment } from '$lib/js/environment/environment_utils';

	const T = new Translator($appStores.language, [
		{
			en: 'Home',
			'zh-tw': '首頁',
		},
		{
			en: 'Sign out',
			'zh-tw': '登出',
		},
		{
			en: 'Sign in',
			'zh-tw': '登入',
		},
	]);

	import LanguageToggle from '$lib/components/Misc/Internationalization/LanguageToggle.svelte';
	import GlobalMenuWrapper from '../GlobalMenu/GlobalMenuWrapper.svelte';
	import GlobalAlert from '$lib/components/Base/Alerts/GlobalAlert.svelte';
	import Badge from '$lib/components/Base/Badges/Badge.svelte';

	let w;

	const signOut = async () => {
		$session.user = undefined;
		$appStores.environment = undefined;
		await supabaseClient.auth.signOut();
	};

	const isProd = checkEnvironment() == 'prod';
	const environment = checkEnvironment();
	const lastEnvironmnent = $appStores.environment;
	const hasChanged =
		(lastEnvironmnent == 'prod' && environment == 'staging') || //
		(lastEnvironmnent == 'staging' && environment == 'prod');

	if (hasChanged) {
		signOut();
	}
</script>

<header class="bg-indigo-600" bind:clientWidth={w}>
	<nav class="container mx-auto">
		<div class="flex w-full flex-row items-center justify-between py-4 text-xs md:text-lg">
			<div class="ml-3 flex w-24 flex-row gap-3">
				{#if $session?.user}
					<div class="grid content-center">
						<a href="/modules" class="btn-md btn-text-white btn-light-indigo btn-solid block" style=""> {T.out('Home')} </a>
					</div>
					<div class="grid content-center">
						<LanguageToggle />
					</div>
				{/if}
			</div>
			{#if $session?.user}
				<div class="flex items-center text-center">
					{#if isProd}
						{#if $session?.user?.email?.includes('mdicki')}
							<Badge color="indigo">
								<span class="whitespace-nowrap">Production</span>
							</Badge>
						{:else}
							<span class="text-xs font-medium text-white md:text-lg">
								{truncateText($session?.user?.email, w < 640 ? 25 : 100)}
							</span>
						{/if}
					{:else}
						<Badge color="orange">
							<span class="whitespace-nowrap">{environment}</span>
						</Badge>
					{/if}
				</div>
			{/if}
			<div class="mr-3 space-x-4" id="sign-out-button">
				<div class="flex justify-end">
					{#if $session?.user}
						<div class="grid content-center">
							<div class="grid content-center">
								<button on:click={signOut} class="btn-md btn-text-white btn-light-indigo btn-solid block" style="">
									{T.out('Sign Out')}
								</button>
							</div>
						</div>
					{:else}
						<div class="grid content-center">
							<a href="/auth/sign-in" class="btn-light-indigo btn-md btn-text-white btn-solid w-full"> {T.out('Sign in')} </a>
						</div>
					{/if}
				</div>
			</div>
		</div>
	</nav>
</header>

<GlobalAlert />
